
@charset "utf-8";
/* layout */
#header {
	@include clearfix;
	.siteTitle {
		float:left;
		margin:20px 0;
	}
	.jpx {
		vertical-align: bottom;
		margin:32px 0 0 15px;
	}
	.headerNavi {
		float:right;
		li {
			display: inline;
		}
		#shopListOpen {
			display: inline-block;
			width: 182px;
			height: 0;
			overflow: hidden;
			padding-top:37px;
			background: url(/assets/img/common/btn_headerShopOff.png) no-repeat 0 0;
			&:hover {
				background: url(/assets/img/common/btn_headerShopOn.png) no-repeat 0 0;
			}
		}
	}
	#gNavi {
		clear:both;
		background:url(/assets/img/common/bg_navLine.png) no-repeat 100% 0;
		li {
			width: (980px / 6);
			float:left;
			background:url(/assets/img/common/bg_navLine.png) no-repeat 0 0;
			a {
				display: block;
				background-image:url(/assets/img/common/nav_global.png);
				background-repeat: no-repeat; 
				height: 0;
				padding-top:45px;
				overflow: hidden;
				border-bottom:4px solid transparent;
				&:hover, &.selected {
					border-bottom:4px solid #000;
				}
			}
		}
		$value1: (980px / 6);
		@for $value2 from 0 through 5 {
			#gNavi0#{$value2} a {
				background-position: -$value1 * $value2 0;
			}
		}
	}
}
#headerBrandList {
		position: absolute;
		top:0;
		left:0;
		z-index:500;
		width: 100%; 
		background-color: #FFF;
		display: none;
		.inner {
			position: relative;
			margin-top:20px;
			#headerClose {
				position: absolute;
				top:-10px;
				right:0;
			}
			#bottomClose {
				position: absolute;
				right:0;
				bottom:-77px;
			}
		}
		li {
			width: (100%/10); 
			float:left;
			font-size:12px;
			@include box-sizing;
			padding:0.2%;
			margin-bottom:5px;
			&:nth-child(10n+1) {
				clear:both;
			}
			img {
				border:1px solid #ccc;
				display: block;
				margin-bottom:5px;
			}
			a {
				text-decoration: none;
			}
			a:hover img {
				@include alpha-image(0.5);
			}
			span {
				background: url(/assets/img/common/ico_blackArrow.png) no-repeat 0 3px;
				-webkit-background-size: 10px 10px; 
				-o-background-size: 10px 10px; 
				background-size: 10px 10px; 
				padding:0 10px 0 15px;
				display: block;
			}
			&:hover span {
				filter:alpha(opacity=50);
				opacity:0.5;
			}
		}
	}

#contents { @include clearfix; }


.container {
	width:$baseWidth;
	@include margin-center;
	line-height:1.8;
}

#brandList {
	margin-bottom:20px;
	li {
		margin:0 1px 5px 0;
		display: inline-block;
		border:1px solid #ccc;
		&:nth-child(10n) {
			margin-right:0;
		}
	}
	a:hover img {
		@include alpha-image(0.5);
	}
}

// Home
#mainVisual {
	margin-bottom:40px;
	width: 100%;
	height: 385px; 
	overflow: hidden; 
}
#home {
	h2 {
		border-left:4px solid #000;
		padding-left:20px;
		margin-bottom:20px;
	}
	#banner {
		.basisBoxlink {
			text-align: center;
		}
	}
}

#news {
	line-height:normal;
	li {
		.basisBoxlink {
			padding-bottom:10px;
		}
	}
	.inner {
		width: 90%;
		@include margin-center;
	}
	.date {
		display: block;
		margin:10px 0 5px;
		font-size:90%;
	}
}

#pageTop {
	text-align: right;
}
#footer {
	background-color: #000;
	color:#FFF;
	padding:30px 0; 
	@include clearfix;
	#footerNavi {
		float:left;
		font-size:130%;
		li {
			display: inline;
			border-right:1px solid darken(#FFF, 30%);
			&:last-child {
				border:none;
			}
			a {
				background: url(/assets/img/common/ico_smallArrow.png) no-repeat 10px 50%;
				padding:3px 20px 3px 30px;
				font-weight:bold;
				color:#FFF;
				text-decoration: none;
			}
		}
	}
	.copyright {
		float:right;
		color:darken(#FFF, 30%);
	}
}

// Lower 
// -----mind-----
.mind {
	&__explanation {
		background-color: #f7f7f7;
		padding:30px;
		@include box-sizing;
		h4 {
			font-size:110%;
			font-weight:bold;
			margin-bottom:10px;
		}
		.span6 {
			padding:10px;
		}
	}
	&__tenpo__left, &__tenpo__right {
		width: 49.5%; 
	}
	&__tenpo__left {
		float:left;
	}
	&__tenpo__right {
		float:right;
	}
}

// -----shop list-----
.shopList {
	line-height:normal;
	.span3 {
		&:nth-child(4n+1) {
			margin-left:0;
		}
		a {
			text-decoration: none;
			margin-bottom:20px;
		}
		img {
			@include margin-center;
			display: block; 
		}
		h3, p {
			width: 90%;
			margin-left:auto;
			margin-right:auto; 
		}
		h3 {
			font-size:120%;
			font-weight:bold;
			border-bottom:1px solid #666;
			line-height:1.2;
			padding-bottom:10px;
			margin-bottom:10px;
		}
		p {
			color: #666;
			margin-bottom:20px; 
		}
	}
}

.shopDetail {
	@include box-sizing;
	padding:30px;
	background-color: #f7f7f7;
	.shopDetailTitle {
		width: 22%;
		float:left;
	}
	.shopDetailText {
		width: 77%;
		float: right;
		border-left:1px solid #666;
		padding-left:30px;
		@include box-sizing;
	}
	h4 {
		font-size:120%;
		font-weight:bold;
		margin-bottom:5px;
	}
}

// -----company-----
.company {
	table {
		width: 100%;
		tr {
			&:first-child {
				th, td {
					padding-top:10px;
				}
			}
		}
		th, td {
			padding:20px;
		}
		th {
			width: 140px; 
			vertical-align: top;
		}
	}
	&__ceoGreeting {
		background: url(/assets/img/lower/company/bg_greeting-frame.png) no-repeat 0 0;
		padding:12px 12px 25px 12px;
		&__inner {
			padding-left: 20px;
			padding-right: 20px;
			margin: 15px auto;
		}
		h4 {
			border-bottom:1px solid $baseColor;
			padding-bottom:15px;
			margin-bottom:15px;
		}
		p {
			font-size:90%;
			line-height:1.6;
		}
	}
	.lineList {
		li {
			padding:20px;
		}
	}
}
// -----recruit-----
.recruitTop {
	&__otherStaff {
		p {
			font-size:90%;
			margin:15px 0 0;
			padding-top:15px;
			border-top:1px solid $baseColor;
			line-height:1.6;
		}
	}
	.boxDoubleFrame {
		.boxCenter {
			margin-top:12px;
		}

	}
}
.recruitMenu {
	background-color: #F7F7F7;
	padding:20px; 
}
.recruitInterview {
	&__faq {
		p, dl {
			font-size:130%;
		}
		.cosGrid {
			width: 900px;
			margin:25px auto; 
		}
		&--answerLeft {
			background: url('/assets/img/lower/recruit/ico_answer-left.png') no-repeat 0 5px;
			padding-left:100px;
		}
		&--answerRight {
			background: url('/assets/img/lower/recruit/ico_answer-right.png') no-repeat 100% 5px;
			padding-right:100px;
		}
		dt {
			font-weight:bold;
			margin-bottom:5px;
		}
		dd {
			margin-bottom:20px;
		}
	}
}

// -----contact-----
.required {
	font-size:70%;
	color: #ff0000;
	vertical-align: top;
	display: inline-block;
	padding-top:3px;
}
.contact {
	&__table {
		width: 95%;
		margin: 0 auto 30px; 
		th {
			font-weight:bold;
			width: 200px; 
		}
		.bgGray {
			background-color: #DDD;
			th, td {
				background-image: none; 
			}
		}
	}
	input[type=text], input[type=tel], input[type=email] {
		padding:3px;
		width: auto;
		max-width: 70%; 
	}
	input[type=radio] {
		&+label {
			margin-left:5px;
			margin-right:40px;
		}
	}
	textarea {
		width: 70%;
	}
	input[type=reset], input[type=submit] {
		border:0;
		height: 0;
		display: inline-block;
		overflow: hidden;
		width: 186px;
		padding-top:42px;
		cursor: pointer;
		background-position: 0 0;
		background-repeat: no-repeat;
		margin:0 10px;   
	}
	input[type=reset] {
		background-image: url('/assets/img/lower/contact/btn_resetOff.png');
		&:hover {
			background-image: url('/assets/img/lower/contact/btn_resetOn.png');	
		}
	}
	input[type=submit] {
		background-image: url('/assets/img/lower/contact/btn_confirmOff.png');
		&:hover {
			background-image: url('/assets/img/lower/contact/btn_confirmOn.png');
		}
	}
	&__store {
		border:1px solid $baseColor;
		padding:5px;
		&__text {
			padding:20px;
		}
	}
}
#contact {
	&__name, &__kana {
		width: 30%; 
	}
	&__address, &__phone, &__email {
		width: 70%; 
	}
}

// -----news-----
.news {
	li {
		a {
			text-decoration: none;
		}
	}
	&__pager {
		margin-top:30px;
		text-align: center;
		li {
			display: inline-block;
			color: #FFF;
			background-color: #CCC; 
			@include border-radius(4px);
			a {
				color: #FFF;
				text-decoration: none;
				display: block;
				padding:5px 10px;
				@include border-radius(4px);
				&:hover {
					background-color: $baseColor; 
				} 
			}
		}
		.current {
			background-color: $baseColor; 
			padding:5px 10px;
			}
	}
	&__date {
		display: block;
		margin-bottom:5px;
		font-size:90%;
	}
	article {
		h3 {
			border-bottom:1px solid $baseColor;
			padding-bottom:15px;
			margin-bottom:15px;
		}
		p {
			margin-bottom:30px;
		}
	}
}

// -----privacy policy-----
.privacypolicy {
	h3 {
		padding:0 0 0 10px;
		font-size:180%;
		border-left:3px solid $baseColor;
		line-height:1;
		font-weight:bold;
		margin-bottom:20px;
	}
	&__line {
		background: url('/assets/img/lower/common/line_dotted.jpg') repeat-x 0 100%;
		padding-bottom:20px;
		margin-bottom:20px;
	}
	h4 {
		font-weight:bold;
		span {
			font-size:150%;
		}
	}
}
