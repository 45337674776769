@charset "utf-8";

/* btn
------------------------------------------------------------*/
.btnLightArrow {
	background: url('/assets/img/common/ico_blackArrow.png') no-repeat 12px 50% #FFF;
	border:1px solid #ccc; 
	padding:5px 20px 5px 35px;
	@include border-radius(4px);
	text-decoration: none;
	font-size:90%;
	&:hover {
		text-decoration: none;
	}
}

/* title
------------------------------------------------------------*/
.leftLineTitle {
	border-left:4px solid #000;
	padding-left:20px;
	margin-bottom:20px;
}

/* table
------------------------------------------------------------*/
.dottedLineTable {
	th, td {
		padding:25px 10px;
		background: url('/assets/img/lower/common/line_dotted.jpg') repeat-x 0 100%;
	}
	th {
		font-weight:bold;
	}
}


/* link
------------------------------------------------------------*/
.permeable:hover img {
	@include alpha-image(0.5);
}
.breadcrumb {
	font-size:90%;
	margin-bottom:30px;
	> * {
		display: inline-block;
		line-height:normal;
	}
	a {
		background: url(/assets/img/lower/common/ico_stepArrow.png) no-repeat 100% 50%;
		padding-right:20px;
		margin-right:10px;
	}
}

/* list
------------------------------------------------------------*/
.listInline {
	li {
		display: inline;
	}
}
.arrow3blockList {
	@include clearfix;
	li {
		width: 33%;
		float: left;
		background-image: url('/assets/img/common/ico_blackArrow.png'), url('/assets/img/lower/common/line_dotted.jpg');
		background-repeat: no-repeat, repeat-x;
		background-position: 0 25px, 0 100%;  
		padding:20px 0 20px 20px;
		@include box-sizing;
		}
	a {
		text-decoration: none;
	}
}
.lineList {
	li {
		padding:25px 0;
		background: url('/assets/img/lower/common/line_dotted.jpg') repeat-x 0 100%;
		span {
			font-weight:bold;
			margin-right:30px;
		}
	}
}


/* block
------------------------------------------------------------*/
.block {
	display: block;
}
.boxCenter {
	@include margin-center;
	display: block;
}

.basisBoxlink {
	border:1px solid #666;
	padding:5px;
	display: block;
	&:hover {
			filter:alpha(opacity=50);
			opacity:0.5;
			text-decoration: none;
	}
}
.bottomArrow {
	position: relative;
	display: block;
	&:after {
		content:url('/assets/img/common/ico_triangleArrow.png');
		right:0;
		bottom:0;
		width: 38px;
		height: 38px;  
		position: absolute;
		display: block;
	}
}
.boxDoubleFrame {
	background: url('/assets/img/lower/recruit/bg_linebox.png') no-repeat 0 0;
	-webkit-background-size: 100%; 
	-o-background-size: 100%; 
	background-size: 100%; 
	@include box-sizing;
	&__inner {
		padding:20px 30px 0;
	}
	&:after {
		content:'';
		display: block;
		width: 100%;
		height: 20px;
		background: url('/assets/img/lower/recruit/bg_linebox-after.png') no-repeat 0 100%;
		-webkit-background-size: 100%; 
		-o-background-size: 100%; 
		background-size: 100%;
	}
}
