@charset "utf-8";

/* width
------------------------------------------------------------*/
%max-width{
	max-width:100%;
}

.cosW100per{width:100%!important; }
.cosW5{width:5px!important;@extend %max-width; }
.cosW10{width:10px!important;@extend %max-width; }
.cosW15{width:15px!important;@extend %max-width; }
.cosW20{width:20px!important;@extend %max-width; }
.cosW25{width:25px!important;@extend %max-width; }
.cosW30{width:30px!important;@extend %max-width; }
.cosW35{width:35px!important;@extend %max-width; }
.cosW40{width:40px!important;@extend %max-width; }
.cosW45{width:45px!important;@extend %max-width; }
.cosW50{width:50px!important;@extend %max-width; }
.cosW55{width:55px!important;@extend %max-width; }
.cosW60{width:60px!important;@extend %max-width; }
.cosW65{width:65px!important;@extend %max-width; }
.cosW70{width:70px!important;@extend %max-width; }
.cosW75{width:75px!important;@extend %max-width; }
.cosW80{width:80px!important;@extend %max-width; }
.cosW85{width:85px!important;@extend %max-width; }
.cosW90{width:90px!important;@extend %max-width; }
.cosW95{width:95px!important;@extend %max-width; }
.cosW100{width:100px!important;@extend %max-width; }
.cosW105{width:105px!important;@extend %max-width; }
.cosW110{width:110px!important;@extend %max-width; }
.cosW115{width:115px!important;@extend %max-width; }
.cosW120{width:120px!important;@extend %max-width; }
.cosW125{width:125px!important;@extend %max-width; }
.cosW130{width:130px!important;@extend %max-width; }
.cosW135{width:135px!important;@extend %max-width; }
.cosW140{width:140px!important;@extend %max-width; }
.cosW145{width:145px!important;@extend %max-width; }
.cosW150{width:150px!important;@extend %max-width; }
.cosW155{width:155px!important;@extend %max-width; }
.cosW160{width:160px!important;@extend %max-width; }
.cosW165{width:165px!important;@extend %max-width; }
.cosW170{width:170px!important;@extend %max-width; }
.cosW175{width:175px!important;@extend %max-width; }
.cosW180{width:180px!important;@extend %max-width; }
.cosW185{width:185px!important;@extend %max-width; }
.cosW190{width:190px!important;@extend %max-width; }
.cosW195{width:195px!important;@extend %max-width; }
.cosW200{width:200px!important;@extend %max-width; }
.cosW205{width:205px!important;@extend %max-width; }
.cosW210{width:210px!important;@extend %max-width; }
.cosW215{width:215px!important;@extend %max-width; }
.cosW220{width:220px!important;@extend %max-width; }
.cosW225{width:225px!important;@extend %max-width; }
.cosW230{width:230px!important;@extend %max-width; }
.cosW235{width:235px!important;@extend %max-width; }
.cosW240{width:240px!important;@extend %max-width; }
.cosW245{width:245px!important;@extend %max-width; }
.cosW250{width:250px!important;@extend %max-width; }
.cosW255{width:255px!important;@extend %max-width; }
.cosW260{width:260px!important;@extend %max-width; }
.cosW265{width:265px!important;@extend %max-width; }
.cosW270{width:270px!important;@extend %max-width; }
.cosW275{width:275px!important;@extend %max-width; }
.cosW280{width:280px!important;@extend %max-width; }
.cosW285{width:285px!important;@extend %max-width; }
.cosW290{width:290px!important;@extend %max-width; }
.cosW295{width:295px!important;@extend %max-width; }
.cosW300{width:300px!important;@extend %max-width; }
.cosW305{width:305px!important;@extend %max-width; }
.cosW310{width:310px!important;@extend %max-width; }
.cosW315{width:315px!important;@extend %max-width; }
.cosW320{width:320px!important;@extend %max-width; }
.cosW325{width:325px!important;@extend %max-width; }
.cosW330{width:330px!important;@extend %max-width; }
.cosW335{width:335px!important;@extend %max-width; }
.cosW340{width:340px!important;@extend %max-width; }
.cosW345{width:345px!important;@extend %max-width; }
.cosW350{width:350px!important;@extend %max-width; }
.cosW355{width:355px!important;@extend %max-width; }
.cosW360{width:360px!important;@extend %max-width; }
.cosW365{width:365px!important;@extend %max-width; }
.cosW370{width:370px!important;@extend %max-width; }
.cosW375{width:375px!important;@extend %max-width; }
.cosW380{width:380px!important;@extend %max-width; }
.cosW385{width:385px!important;@extend %max-width; }
.cosW390{width:390px!important;@extend %max-width; }
.cosW395{width:395px!important;@extend %max-width; }
.cosW400{width:400px!important;@extend %max-width; }
.cosW405{width:405px!important;@extend %max-width; }
.cosW410{width:410px!important;@extend %max-width; }
.cosW415{width:415px!important;@extend %max-width; }
.cosW420{width:420px!important;@extend %max-width; }
.cosW425{width:425px!important;@extend %max-width; }
.cosW430{width:430px!important;@extend %max-width; }
.cosW435{width:435px!important;@extend %max-width; }
.cosW440{width:440px!important;@extend %max-width; }
.cosW445{width:445px!important;@extend %max-width; }
.cosW450{width:450px!important;@extend %max-width; }
.cosW455{width:455px!important;@extend %max-width; }
.cosW460{width:460px!important;@extend %max-width; }
.cosW465{width:465px!important;@extend %max-width; }
.cosW470{width:470px!important;@extend %max-width; }
.cosW475{width:475px!important;@extend %max-width; }
.cosW480{width:480px!important;@extend %max-width; }
.cosW485{width:485px!important;@extend %max-width; }
.cosW490{width:490px!important;@extend %max-width; }
.cosW495{width:495px!important;@extend %max-width; }
.cosW500{width:500px!important;@extend %max-width; }
.cosW505{width:505px!important;@extend %max-width; }
.cosW510{width:510px!important;@extend %max-width; }
.cosW515{width:515px!important;@extend %max-width; }
.cosW520{width:520px!important;@extend %max-width; }
.cosW525{width:525px!important;@extend %max-width; }
.cosW530{width:530px!important;@extend %max-width; }
.cosW535{width:535px!important;@extend %max-width; }
.cosW540{width:540px!important;@extend %max-width; }
.cosW545{width:545px!important;@extend %max-width; }
.cosW550{width:550px!important;@extend %max-width; }
.cosW555{width:555px!important;@extend %max-width; }
.cosW560{width:560px!important;@extend %max-width; }
.cosW565{width:565px!important;@extend %max-width; }
.cosW570{width:570px!important;@extend %max-width; }
.cosW575{width:575px!important;@extend %max-width; }
.cosW580{width:580px!important;@extend %max-width; }
.cosW585{width:585px!important;@extend %max-width; }
.cosW590{width:590px!important;@extend %max-width; }
.cosW595{width:595px!important;@extend %max-width; }
.cosW600{width:600px!important;@extend %max-width; }
.cosW605{width:605px!important;@extend %max-width; }
.cosW610{width:610px!important;@extend %max-width; }
.cosW615{width:615px!important;@extend %max-width; }
.cosW620{width:620px!important;@extend %max-width; }
.cosW625{width:625px!important;@extend %max-width; }
.cosW630{width:630px!important;@extend %max-width; }
.cosW635{width:635px!important;@extend %max-width; }
.cosW640{width:640px!important;@extend %max-width; }
.cosW645{width:645px!important;@extend %max-width; }
.cosW650{width:650px!important;@extend %max-width; }
.cosW655{width:655px!important;@extend %max-width; }
.cosW660{width:660px!important;@extend %max-width; }
.cosW665{width:665px!important;@extend %max-width; }
.cosW670{width:670px!important;@extend %max-width; }
.cosW675{width:675px!important;@extend %max-width; }
.cosW680{width:680px!important;@extend %max-width; }
.cosW685{width:685px!important;@extend %max-width; }
.cosW690{width:690px!important;@extend %max-width; }
.cosW695{width:695px!important;@extend %max-width; }
.cosW700{width:700px!important;@extend %max-width; }
.cosW705{width:705px!important;@extend %max-width; }
.cosW710{width:710px!important;@extend %max-width; }
.cosW715{width:715px!important;@extend %max-width; }
.cosW720{width:720px!important;@extend %max-width; }
.cosW725{width:725px!important;@extend %max-width; }
.cosW730{width:730px!important;@extend %max-width; }
.cosW735{width:735px!important;@extend %max-width; }
.cosW740{width:740px!important;@extend %max-width; }
.cosW745{width:745px!important;@extend %max-width; }
.cosW750{width:750px!important;@extend %max-width; }
.cosW755{width:755px!important;@extend %max-width; }
.cosW760{width:760px!important;@extend %max-width; }
.cosW765{width:765px!important;@extend %max-width; }
.cosW770{width:770px!important;@extend %max-width; }
.cosW775{width:775px!important;@extend %max-width; }
.cosW780{width:780px!important;@extend %max-width; }
.cosW785{width:785px!important;@extend %max-width; }
.cosW790{width:790px!important;@extend %max-width; }
.cosW795{width:795px!important;@extend %max-width; }
.cosW800{width:800px!important;@extend %max-width; }
.cosW805{width:805px!important;@extend %max-width; }
.cosW810{width:810px!important;@extend %max-width; }
.cosW815{width:815px!important;@extend %max-width; }
.cosW820{width:820px!important;@extend %max-width; }
.cosW825{width:825px!important;@extend %max-width; }
.cosW830{width:830px!important;@extend %max-width; }
.cosW835{width:835px!important;@extend %max-width; }
.cosW840{width:840px!important;@extend %max-width; }
.cosW845{width:845px!important;@extend %max-width; }
.cosW850{width:850px!important;@extend %max-width; }
.cosW855{width:855px!important;@extend %max-width; }
.cosW860{width:860px!important;@extend %max-width; }
.cosW865{width:865px!important;@extend %max-width; }
.cosW870{width:870px!important;@extend %max-width; }
.cosW875{width:875px!important;@extend %max-width; }
.cosW880{width:880px!important;@extend %max-width; }
.cosW885{width:885px!important;@extend %max-width; }
.cosW890{width:890px!important;@extend %max-width; }
.cosW895{width:895px!important;@extend %max-width; }

/* margin,padding
------------------------------------------------------------*/
.mRLAuto { @include margin-center; }

.cosMl0 { margin-left: 0px!important; }
.cosMl5 { margin-left: 5px!important; }
.cosMl10 { margin-left: 10px!important; }
.cosMl20 { margin-left: 20px!important; }
.cosMl30 { margin-left: 30px!important; }
.cosMl40 { margin-left: 40px!important; }
.cosMl50 { margin-left: 50px!important; }
.cosMl60 { margin-left: 60px!important; }
.cosMl70 { margin-left: 70px!important; }
.cosMl80 { margin-left: 80px!important; }
.cosMl90 { margin-left: 90px!important; }
.cosMl100 { margin-left: 100px!important; }
.cosMl110 { margin-left: 110px!important; }
.cosMl120 { margin-left: 120px!important; }
.cosMl130 { margin-left: 130px!important; }
.cosMl140 { margin-left: 140px!important; }
.cosMl150 { margin-left: 150px!important; }
.cosMl160 { margin-left: 160px!important; }
.cosMl170 { margin-left: 170px!important; }
.cosMl180 { margin-left: 180px!important; }
.cosMl190 { margin-left: 190px!important; }
.cosMl200 { margin-left: 200px!important; }
.cosMl210 { margin-left: 210px!important; }
.cosMl220 { margin-left: 220px!important; }
.cosMl230 { margin-left: 230px!important; }
.cosMl240 { margin-left: 240px!important; }
.cosMl250 { margin-left: 250px!important; }
.cosMl260 { margin-left: 260px!important; }
.cosMl270 { margin-left: 270px!important; }
.cosMl280 { margin-left: 280px!important; }
.cosMl290 { margin-left: 290px!important; }
.cosMl300 { margin-left: 300px!important; }
.cosMt0 { margin-top: 0px!important; }
.cosMt5 { margin-top: 5px!important; }
.cosMt10 { margin-top: 10px!important; }
.cosMt15 { margin-top: 15px!important; }
.cosMt20 { margin-top: 20px!important; }
.cosMt30 { margin-top: 30px!important; }
.cosMt40 { margin-top: 40px!important; }
.cosMt50 { margin-top: 50px!important; }
.cosMt60 { margin-top: 60px!important; }
.cosMt70 { margin-top: 70px!important; }
.cosMt80 { margin-top: 80px!important; }
.cosMt90 { margin-top: 90px!important; }
.cosMt100 { margin-top: 100px!important; }
.cosMt110 { margin-top: 110px!important; }
.cosMt120 { margin-top: 120px!important; }
.cosMt130 { margin-top: 130px!important; }
.cosMt140 { margin-top: 140px!important; }
.cosMt150 { margin-top: 150px!important; }
.cosMt160 { margin-top: 160px!important; }
.cosMt170 { margin-top: 170px!important; }
.cosMt180 { margin-top: 180px!important; }
.cosMt190 { margin-top: 190px!important; }
.cosMt200 { margin-top: 200px!important; }
.cosMt210 { margin-top: 210px!important; }
.cosMt220 { margin-top: 220px!important; }
.cosMt230 { margin-top: 230px!important; }
.cosMt240 { margin-top: 240px!important; }
.cosMt250 { margin-top: 250px!important; }
.cosMt260 { margin-top: 260px!important; }
.cosMt270 { margin-top: 270px!important; }
.cosMt280 { margin-top: 280px!important; }
.cosMt290 { margin-top: 290px!important; }
.cosMt300 { margin-top: 300px!important; }
.cosMr0 { margin-right: 0px!important; }
.cosMr5 { margin-right: 5px!important; }
.cosMr10 { margin-right: 10px!important; }
.cosMr20 { margin-right: 20px!important; }
.cosMr30 { margin-right: 30px!important; }
.cosMr40 { margin-right: 40px!important; }
.cosMr50 { margin-right: 50px!important; }
.cosMr60 { margin-right: 60px!important; }
.cosMr70 { margin-right: 70px!important; }
.cosMr80 { margin-right: 80px!important; }
.cosMr90 { margin-right: 90px!important; }
.cosMr100 { margin-right: 100px!important; }
.cosMr110 { margin-right: 110px!important; }
.cosMr120 { margin-right: 120px!important; }
.cosMr130 { margin-right: 130px!important; }
.cosMr140 { margin-right: 140px!important; }
.cosMr150 { margin-right: 150px!important; }
.cosMr160 { margin-right: 160px!important; }
.cosMr170 { margin-right: 170px!important; }
.cosMr180 { margin-right: 180px!important; }
.cosMr190 { margin-right: 190px!important; }
.cosMr200 { margin-right: 200px!important; }
.cosMr210 { margin-right: 210px!important; }
.cosMr220 { margin-right: 220px!important; }
.cosMr230 { margin-right: 230px!important; }
.cosMr240 { margin-right: 240px!important; }
.cosMr250 { margin-right: 250px!important; }
.cosMr260 { margin-right: 260px!important; }
.cosMr270 { margin-right: 270px!important; }
.cosMr280 { margin-right: 280px!important; }
.cosMr290 { margin-right: 290px!important; }
.cosMr300 { margin-right: 300px!important; }
.cosMb0 { margin-bottom: 0px!important; }
.cosMb5 { margin-bottom: 5px!important; }
.cosMb10 { margin-bottom: 10px!important; }
.cosMb15 { margin-bottom: 15px!important; }
.cosMb20 { margin-bottom: 20px!important; }
.cosMb30 { margin-bottom: 30px!important; }
.cosMb40 { margin-bottom: 40px!important; }
.cosMb50 { margin-bottom: 50px!important; }
.cosMb60 { margin-bottom: 60px!important; }
.cosMb70 { margin-bottom: 70px!important; }
.cosMb80 { margin-bottom: 80px!important; }
.cosMb90 { margin-bottom: 90px!important; }
.cosMb100 { margin-bottom: 100px!important; }
.cosMb110 { margin-bottom: 110px!important; }
.cosMb120 { margin-bottom: 120px!important; }
.cosMb130 { margin-bottom: 130px!important; }
.cosMb140 { margin-bottom: 140px!important; }
.cosMb150 { margin-bottom: 150px!important; }
.cosMb160 { margin-bottom: 160px!important; }
.cosMb170 { margin-bottom: 170px!important; }
.cosMb180 { margin-bottom: 180px!important; }
.cosMb190 { margin-bottom: 190px!important; }
.cosMb200 { margin-bottom: 200px!important; }
.cosMb210 { margin-bottom: 210px!important; }
.cosMb220 { margin-bottom: 220px!important; }
.cosMb230 { margin-bottom: 230px!important; }
.cosMb240 { margin-bottom: 240px!important; }
.cosMb250 { margin-bottom: 250px!important; }
.cosMb260 { margin-bottom: 260px!important; }
.cosMb270 { margin-bottom: 270px!important; }
.cosMb280 { margin-bottom: 280px!important; }
.cosMb290 { margin-bottom: 290px!important; }
.cosMb300 { margin-bottom: 300px!important; }
.cosPl0 { padding-left: 0px!important; }
.cosPl5 { padding-left: 5px!important; }
.cosPl10 { padding-left: 10px!important; }
.cosPl20 { padding-left: 20px!important; }
.cosPl30 { padding-left: 30px!important; }
.cosPl40 { padding-left: 40px!important; }
.cosPl50 { padding-left: 50px!important; }
.cosPl60 { padding-left: 60px!important; }
.cosPl70 { padding-left: 70px!important; }
.cosPl80 { padding-left: 80px!important; }
.cosPl90 { padding-left: 90px!important; }
.cosPl100 { padding-left: 100px!important; }
.cosPl110 { padding-left: 110px!important; }
.cosPl120 { padding-left: 120px!important; }
.cosPl130 { padding-left: 130px!important; }
.cosPl140 { padding-left: 140px!important; }
.cosPl150 { padding-left: 150px!important; }
.cosPl160 { padding-left: 160px!important; }
.cosPl170 { padding-left: 170px!important; }
.cosPl180 { padding-left: 180px!important; }
.cosPl190 { padding-left: 190px!important; }
.cosPl200 { padding-left: 200px!important; }
.cosPl210 { padding-left: 210px!important; }
.cosPl220 { padding-left: 220px!important; }
.cosPl230 { padding-left: 230px!important; }
.cosPl240 { padding-left: 240px!important; }
.cosPl250 { padding-left: 250px!important; }
.cosPl260 { padding-left: 260px!important; }
.cosPl270 { padding-left: 270px!important; }
.cosPl280 { padding-left: 280px!important; }
.cosPl290 { padding-left: 290px!important; }
.cosPl300 { padding-left: 300px!important; }
.cosPt0 { padding-top: 0px!important; }
.cosPt5 { padding-top: 5px!important; }
.cosPt10 { padding-top: 10px!important; }
.cosPt20 { padding-top: 20px!important; }
.cosPt30 { padding-top: 30px!important; }
.cosPt40 { padding-top: 40px!important; }
.cosPt50 { padding-top: 50px!important; }
.cosPt60 { padding-top: 60px!important; }
.cosPt70 { padding-top: 70px!important; }
.cosPt80 { padding-top: 80px!important; }
.cosPt90 { padding-top: 90px!important; }
.cosPt100 { padding-top: 100px!important; }
.cosPt110 { padding-top: 110px!important; }
.cosPt120 { padding-top: 120px!important; }
.cosPt130 { padding-top: 130px!important; }
.cosPt140 { padding-top: 140px!important; }
.cosPt150 { padding-top: 150px!important; }
.cosPt160 { padding-top: 160px!important; }
.cosPt170 { padding-top: 170px!important; }
.cosPt180 { padding-top: 180px!important; }
.cosPt190 { padding-top: 190px!important; }
.cosPt200 { padding-top: 200px!important; }
.cosPt210 { padding-top: 210px!important; }
.cosPt220 { padding-top: 220px!important; }
.cosPt230 { padding-top: 230px!important; }
.cosPt240 { padding-top: 240px!important; }
.cosPt250 { padding-top: 250px!important; }
.cosPt260 { padding-top: 260px!important; }
.cosPt270 { padding-top: 270px!important; }
.cosPt280 { padding-top: 280px!important; }
.cosPt290 { padding-top: 290px!important; }
.cosPt300 { padding-top: 300px!important; }
.cosPr0 { padding-right: 0px!important; }
.cosPr5 { padding-right: 5px!important; }
.cosPr10 { padding-right: 10px!important; }
.cosPr20 { padding-right: 20px!important; }
.cosPr30 { padding-right: 30px!important; }
.cosPr40 { padding-right: 40px!important; }
.cosPr50 { padding-right: 50px!important; }
.cosPr60 { padding-right: 60px!important; }
.cosPr70 { padding-right: 70px!important; }
.cosPr80 { padding-right: 80px!important; }
.cosPr90 { padding-right: 90px!important; }
.cosPr100 { padding-right: 100px!important; }
.cosPr110 { padding-right: 110px!important; }
.cosPr120 { padding-right: 120px!important; }
.cosPr130 { padding-right: 130px!important; }
.cosPr140 { padding-right: 140px!important; }
.cosPr150 { padding-right: 150px!important; }
.cosPr160 { padding-right: 160px!important; }
.cosPr170 { padding-right: 170px!important; }
.cosPr180 { padding-right: 180px!important; }
.cosPr190 { padding-right: 190px!important; }
.cosPr200 { padding-right: 200px!important; }
.cosPr210 { padding-right: 210px!important; }
.cosPr220 { padding-right: 220px!important; }
.cosPr230 { padding-right: 230px!important; }
.cosPr240 { padding-right: 240px!important; }
.cosPr250 { padding-right: 250px!important; }
.cosPr260 { padding-right: 260px!important; }
.cosPr270 { padding-right: 270px!important; }
.cosPr280 { padding-right: 280px!important; }
.cosPr290 { padding-right: 290px!important; }
.cosPr300 { padding-right: 300px!important; }
.cosPb0 { padding-bottom: 0px!important; }
.cosPb5 { padding-bottom: 5px!important; }
.cosPb10 { padding-bottom: 10px!important; }
.cosPb20 { padding-bottom: 20px!important; }
.cosPb30 { padding-bottom: 30px!important; }
.cosPb40 { padding-bottom: 40px!important; }
.cosPb50 { padding-bottom: 50px!important; }
.cosPb60 { padding-bottom: 60px!important; }
.cosPb70 { padding-bottom: 70px!important; }
.cosPb80 { padding-bottom: 80px!important; }
.cosPb90 { padding-bottom: 90px!important; }
.cosPb100 { padding-bottom: 100px!important; }
.cosPb110 { padding-bottom: 110px!important; }
.cosPb120 { padding-bottom: 120px!important; }
.cosPb130 { padding-bottom: 130px!important; }
.cosPb140 { padding-bottom: 140px!important; }
.cosPb150 { padding-bottom: 150px!important; }
.cosPb160 { padding-bottom: 160px!important; }
.cosPb170 { padding-bottom: 170px!important; }
.cosPb180 { padding-bottom: 180px!important; }
.cosPb190 { padding-bottom: 190px!important; }
.cosPb200 { padding-bottom: 200px!important; }
.cosPb210 { padding-bottom: 210px!important; }
.cosPb220 { padding-bottom: 220px!important; }
.cosPb230 { padding-bottom: 230px!important; }
.cosPb240 { padding-bottom: 240px!important; }
.cosPb250 { padding-bottom: 250px!important; }
.cosPb260 { padding-bottom: 260px!important; }
.cosPb270 { padding-bottom: 270px!important; }
.cosPb280 { padding-bottom: 280px!important; }
.cosPb290 { padding-bottom: 290px!important; }
.cosPb300 { padding-bottom: 300px!important; }

/* text
------------------------------------------------------------*/
.cosTxtBold { font-weight: bold!important; }
.cosTxtRight { text-align: right!important; }
.cosTxtCenter { text-align: center!important; }
.cosTxtLeft{ text-align:left!important;}
.cosTxt10 { @include fz(10,true); }
.cosTxt11 { @include fz(11,true); }
.cosTxt12 { @include fz(12,true); }
.cosTxt13 { @include fz(13,true); }
.cosTxt14 { @include fz(14,true); }
.cosTxt16 { @include fz(16,true); }
.cosTxt18 { @include fz(18,true); }
.cosTxt20 { @include fz(20,true); }

/* grid
------------------------------------------------------------*/
.cosGrid {
	width: 100%;
	*zoom: 1;
}
.cosGrid:before,
.cosGrid:after {
	display: table;
	line-height: 0;
	content: "";
}
.cosGrid:after {
	clear: both;
}
.cosGrid [class*="span"] {
	display: block;
	float: left;
	width: 100%;
	min-height: 30px;
	margin-left: 2.127659574468085%;
*margin-left: 2.074468085106383%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.cosGrid [class*="span"]:first-child {
	margin-left: 0;
}
.cosGrid .controls-row [class*="span"] + [class*="span"] {
	margin-left: 2.127659574468085%;
}
.cosGrid .span12 {
	width: 100%;
*width: 99.94680851063829%;
}
.cosGrid .span11 {
	width: 91.48936170212765%;
*width: 91.43617021276594%;
}
.cosGrid .span10 {
	width: 82.97872340425532%;
*width: 82.92553191489361%;
}
.cosGrid .span9 {
	width: 74.46808510638297%;
*width: 74.41489361702126%;
}
.cosGrid .span8 {
	width: 65.95744680851064%;
*width: 65.90425531914893%;
}
.cosGrid .span7 {
	width: 57.44680851063829%;
*width: 57.39361702127659%;
}
.cosGrid .span6 {
	width: 48.93617021276595%;
*width: 48.88297872340425%;
}
.cosGrid .span5 {
	width: 40.42553191489362%;
*width: 40.37234042553192%;
}
.cosGrid .span4 {
	width: 31.914893617021278%;
*width: 31.861702127659576%;
}
.cosGrid .span3 {
	width: 23.404255319148934%;
*width: 23.351063829787233%;
}
.cosGrid .span2 {
	width: 14.893617021276595%;
*width: 14.840425531914894%;
}
.cosGrid .span1 {
	width: 6.382978723404255%;
*width: 6.329787234042553%;
}
.cosGrid .offset12 {
	margin-left: 104.25531914893617%;
*margin-left: 104.14893617021275%;
}
.cosGrid .offset12:first-child {
	margin-left: 102.12765957446808%;
*margin-left: 102.02127659574467%;
}
.cosGrid .offset11 {
	margin-left: 95.74468085106382%;
*margin-left: 95.6382978723404%;
}
.cosGrid .offset11:first-child {
	margin-left: 93.61702127659574%;
*margin-left: 93.51063829787232%;
}
.cosGrid .offset10 {
	margin-left: 87.23404255319149%;
*margin-left: 87.12765957446807%;
}
.cosGrid .offset10:first-child {
	margin-left: 85.1063829787234%;
*margin-left: 84.99999999999999%;
}
.cosGrid .offset9 {
	margin-left: 78.72340425531914%;
*margin-left: 78.61702127659572%;
}
.cosGrid .offset9:first-child {
	margin-left: 76.59574468085106%;
*margin-left: 76.48936170212764%;
}
.cosGrid .offset8 {
	margin-left: 70.2127659574468%;
*margin-left: 70.10638297872339%;
}
.cosGrid .offset8:first-child {
	margin-left: 68.08510638297872%;
*margin-left: 67.9787234042553%;
}
.cosGrid .offset7 {
	margin-left: 61.70212765957446%;
*margin-left: 61.59574468085106%;
}
.cosGrid .offset7:first-child {
	margin-left: 59.574468085106375%;
*margin-left: 59.46808510638297%;
}
.cosGrid .offset6 {
	margin-left: 53.191489361702125%;
*margin-left: 53.085106382978715%;
}
.cosGrid .offset6:first-child {
	margin-left: 51.063829787234035%;
*margin-left: 50.95744680851063%;
}
.cosGrid .offset5 {
	margin-left: 44.68085106382979%;
*margin-left: 44.57446808510638%;
}
.cosGrid .offset5:first-child {
	margin-left: 42.5531914893617%;
*margin-left: 42.4468085106383%;
}
.cosGrid .offset4 {
	margin-left: 36.170212765957444%;
*margin-left: 36.06382978723405%;
}
.cosGrid .offset4:first-child {
	margin-left: 34.04255319148936%;
*margin-left: 33.93617021276596%;
}
.cosGrid .offset3 {
	margin-left: 27.659574468085104%;
*margin-left: 27.5531914893617%;
}
.cosGrid .offset3:first-child {
	margin-left: 25.53191489361702%;
*margin-left: 25.425531914893618%;
}
.cosGrid .offset2 {
	margin-left: 19.148936170212764%;
*margin-left: 19.04255319148936%;
}
.cosGrid .offset2:first-child {
	margin-left: 17.02127659574468%;
*margin-left: 16.914893617021278%;
}
.cosGrid .offset1 {
	margin-left: 10.638297872340425%;
*margin-left: 10.53191489361702%;
}
.cosGrid .offset1:first-child {
	margin-left: 8.51063829787234%;
*margin-left: 8.404255319148938%;
}
// .cosGrid [class*="span"] {
// 		@include tabletP{
// 			display: block;
// 			float: none;
// 			width: 100%;
// 			margin-left: 0;
// 			margin-bottom:20px;
// 			-webkit-box-sizing: border-box;
// 				 -moz-box-sizing: border-box;
// 							box-sizing: border-box;
// 		}
//   }
//   .cosGrid .span12 {
// 		@include tabletP{
// 			width: 100%;
// 			-webkit-box-sizing: border-box;
// 				 -moz-box-sizing: border-box;
// 							box-sizing: border-box;
// 		}
//   }
//   .cosGrid [class*="offset"]:first-child {
// 		@include tabletP{
// 			margin-left: 0;
// 		}
//   }



.cosGridXs {
	width: 100%;
	*zoom: 1;
}
.cosGridXs:before,
.cosGridXs:after {
	display: table;
	line-height: 0;
	content: "";
}
.cosGridXs:after {
	clear: both;
}
.cosGridXs [class*="span"] {
	display: block;
	float: left;
	width: 100%;
	min-height: 30px;
	margin-left: 2.127659574468085%;
*margin-left: 2.074468085106383%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.cosGridXs [class*="span"]:first-child {
	margin-left: 0;
}
.cosGridXs .controls-row [class*="span"] + [class*="span"] {
	margin-left: 2.127659574468085%;
}
.cosGridXs .span12 {
	width: 100%;
*width: 99.94680851063829%;
}
.cosGridXs .span11 {
	width: 91.48936170212765%;
*width: 91.43617021276594%;
}
.cosGridXs .span10 {
	width: 82.97872340425532%;
*width: 82.92553191489361%;
}
.cosGridXs .span9 {
	width: 74.46808510638297%;
*width: 74.41489361702126%;
}
.cosGridXs .span8 {
	width: 65.95744680851064%;
*width: 65.90425531914893%;
}
.cosGridXs .span7 {
	width: 57.44680851063829%;
*width: 57.39361702127659%;
}
.cosGridXs .span6 {
	width: 48.93617021276595%;
*width: 48.88297872340425%;
}
.cosGridXs .span5 {
	width: 40.42553191489362%;
*width: 40.37234042553192%;
}
.cosGridXs .span4 {
	width: 31.914893617021278%;
*width: 31.861702127659576%;
}
.cosGridXs .span3 {
	width: 23.404255319148934%;
*width: 23.351063829787233%;
}
.cosGridXs .span2 {
	width: 14.893617021276595%;
*width: 14.840425531914894%;
}
.cosGridXs .span1 {
	width: 6.382978723404255%;
*width: 6.329787234042553%;
}
.cosGridXs .offset12 {
	margin-left: 104.25531914893617%;
*margin-left: 104.14893617021275%;
}
.cosGridXs .offset12:first-child {
	margin-left: 102.12765957446808%;
*margin-left: 102.02127659574467%;
}
.cosGridXs .offset11 {
	margin-left: 95.74468085106382%;
*margin-left: 95.6382978723404%;
}
.cosGridXs .offset11:first-child {
	margin-left: 93.61702127659574%;
*margin-left: 93.51063829787232%;
}
.cosGridXs .offset10 {
	margin-left: 87.23404255319149%;
*margin-left: 87.12765957446807%;
}
.cosGridXs .offset10:first-child {
	margin-left: 85.1063829787234%;
*margin-left: 84.99999999999999%;
}
.cosGridXs .offset9 {
	margin-left: 78.72340425531914%;
*margin-left: 78.61702127659572%;
}
.cosGridXs .offset9:first-child {
	margin-left: 76.59574468085106%;
*margin-left: 76.48936170212764%;
}
.cosGridXs .offset8 {
	margin-left: 70.2127659574468%;
*margin-left: 70.10638297872339%;
}
.cosGridXs .offset8:first-child {
	margin-left: 68.08510638297872%;
*margin-left: 67.9787234042553%;
}
.cosGridXs .offset7 {
	margin-left: 61.70212765957446%;
*margin-left: 61.59574468085106%;
}
.cosGridXs .offset7:first-child {
	margin-left: 59.574468085106375%;
*margin-left: 59.46808510638297%;
}
.cosGridXs .offset6 {
	margin-left: 53.191489361702125%;
*margin-left: 53.085106382978715%;
}
.cosGridXs .offset6:first-child {
	margin-left: 51.063829787234035%;
*margin-left: 50.95744680851063%;
}
.cosGridXs .offset5 {
	margin-left: 44.68085106382979%;
*margin-left: 44.57446808510638%;
}
.cosGridXs .offset5:first-child {
	margin-left: 42.5531914893617%;
*margin-left: 42.4468085106383%;
}
.cosGridXs .offset4 {
	margin-left: 36.170212765957444%;
*margin-left: 36.06382978723405%;
}
.cosGridXs .offset4:first-child {
	margin-left: 34.04255319148936%;
*margin-left: 33.93617021276596%;
}
.cosGridXs .offset3 {
	margin-left: 27.659574468085104%;
*margin-left: 27.5531914893617%;
}
.cosGridXs .offset3:first-child {
	margin-left: 25.53191489361702%;
*margin-left: 25.425531914893618%;
}
.cosGridXs .offset2 {
	margin-left: 19.148936170212764%;
*margin-left: 19.04255319148936%;
}
.cosGridXs .offset2:first-child {
	margin-left: 17.02127659574468%;
*margin-left: 16.914893617021278%;
}
.cosGridXs .offset1 {
	margin-left: 10.638297872340425%;
*margin-left: 10.53191489361702%;
}
.cosGridXs .offset1:first-child {
	margin-left: 8.51063829787234%;
*margin-left: 8.404255319148938%;
}


/* etc
------------------------------------------------------------*/
.cosHide { display:none; }