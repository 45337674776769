@charset "utf-8";

// font
$baseFont: 14;
$baseLineHeight: 1.5;
$baseColor: #000;
$baseLinkColor: #000;
$mainColor: #000;


// font-family
$baseFontFamily : "メイリオ",Verdana, "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, Meiryo, sans-serif;
$fontAlpha : Helvetica, Arial, "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, Meiryo, sans-serif;
$fontMincho : "游明朝体", "Yu Mincho",'ヒラギノ明朝 Pro W3','Hiragino Mincho Pro',"メイリオ", Meiryo, serif;


//decoration
$baseRadius: 3px;

// icon webfont
$arrowR : "\f105";
$arrowL : "\f104";
$iconHome : "\f015";


// width
$baseWidth: 980px;
$baseWidthWide: 980px;

// point
$bpTabletL: 979px;
$bpTabletP: 768px;
$bpSphoneP: 480px;